<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Verificación de datos</h4>
    <button type="button" class="close" (click)="cancel()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <app-customer-verify-phone-and-email
    *ngIf="needShowToVerify(verifyEmailType)"
    [customerDataToVerify]="customerDataToVerify"
    [verifyTokenType]="verifyEmailType"
    [customerCode]="customerCode"
    (verificationComplete)="handleVerificationComplete($event,verifyEmailType)"
  ></app-customer-verify-phone-and-email>
  <app-customer-verify-phone-and-email
    *ngIf="needShowToVerify(verifyPhoneType)"
    [customerDataToVerify]="customerDataToVerify"
    [verifyTokenType]="verifyPhoneType"
    [customerCode]="customerCode"
    (verificationComplete)="handleVerificationComplete($event,verifyEmailType)"
  ></app-customer-verify-phone-and-email>
</div>
