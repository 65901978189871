import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VerifyCustomerValidateTokenType } from 'src/app/@core/constants';
import { PosReasonSelectModalService } from 'src/app/@core/services/pos-reason-select-modal.service';
import { CatalogsResponse } from 'src/app/models/catalogs';
import { ReasonLogRequest } from 'src/app/models/request/reason-log-request-model';
import { VerifyCustomerCheckResponseData } from 'src/app/models/response/VerifyCustomerCheckResponse';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerVerificationService } from 'src/app/services/customer-verification.service';

@Component({
  selector: 'app-pos-verify-customer',
  templateUrl: './pos-verify-customer.component.html',
  styleUrls: ['./pos-verify-customer.component.sass'],
})
export class PosVerifyCustomerComponent implements OnInit {
  @Input() customerDataToVerify: VerifyCustomerCheckResponseData | undefined;
  @Input() customerCode: string = '';
  @Output() verificationComplete = new EventEmitter<boolean>(false);
  verifyEmailType = VerifyCustomerValidateTokenType.EMAIL;
  verifyPhoneType = VerifyCustomerValidateTokenType.PHONE;

  emailVerified = false;
  phoneVerified = false;
  constructor(public bsModalRef: BsModalRef,
    private reasonSelectModalService:PosReasonSelectModalService,
    private configService:ConfigurationService,
    private authService:AuthService,
    private customerVerificationService:CustomerVerificationService) {}

  ngOnInit(): void {}

  async cancel() {
    if(!this.emailVerified && !this.phoneVerified){
      const reason = await this.reasonSelectModalService.show('notVerifyCustomer');
      await this.saveReasonLog(reason!);
    }
    this.bsModalRef.hide();
    this.verificationComplete.emit(false);
    this.verificationComplete.complete();
  }

  handleVerificationComplete(success: boolean, verifyTokenType:VerifyCustomerValidateTokenType) {
    if(verifyTokenType == VerifyCustomerValidateTokenType.EMAIL){
      this.emailVerified = success;
    }

    if(verifyTokenType == VerifyCustomerValidateTokenType.PHONE){
      this.phoneVerified = success;
    }

    if(this.customerDataToVerify?.email.email != undefined && this.customerDataToVerify?.phone.phone_number != undefined && this.emailVerified && this.phoneVerified){
      this.verificationComplete.emit(success);
      this.verificationComplete.complete();
      this.cancel();
    }

    if(this.customerDataToVerify?.email.email == undefined && this.customerDataToVerify?.phone.phone_number != undefined && !this.emailVerified && this.phoneVerified){
      this.verificationComplete.emit(success);
      this.verificationComplete.complete();
      this.cancel();
    }

    if(this.customerDataToVerify?.email.email != undefined && this.customerDataToVerify?.phone.phone_number == undefined && this.emailVerified && !this.phoneVerified){
      this.verificationComplete.emit(success);
      this.verificationComplete.complete();
      this.cancel();
    }

  }

  async saveReasonLog(selectedReason:CatalogsResponse):Promise<void>{
    const requestToSaveReasonLog:ReasonLogRequest = {
      branchCode:this.configService.readSelectedBranchAndSalesOrg().branch.code,
      component:'VERIFY_CUSTOMER',
      customerCode:this.customerCode,
      description:selectedReason?.description || "",
      reasonCode:selectedReason?.itemCode || "RA",
      sellerCode:(await this.authService.readProfile()).username,
      salesOrgCode:this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
      yesOrNo:'NO'
    };

    await this.customerVerificationService.saveReasonToNotUpdateCustomer(requestToSaveReasonLog).toPromise();
  }

  needShowToVerify(verifyType:VerifyCustomerValidateTokenType):boolean{
    if(verifyType === VerifyCustomerValidateTokenType.EMAIL){
      if(this.customerDataToVerify?.email.email != undefined){
        if(this.customerDataToVerify?.email.verified){
          return false;
        }else{
          return true;
        }
      }else{
        return false;
      }
    }else{
      if(this.customerDataToVerify?.phone.phone_number != undefined){
        if(this.customerDataToVerify?.phone.verified){
          return false;
        }else{
          return true;
        }
      }else{
        return false;
      }
    }


  }
}
