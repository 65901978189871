import { DatePipe, Location } from '@angular/common';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CountryHN, CurrentCountry, OnBaseCatalogueType, ParamPhoneMaskHn, ParamPhoneMaskNoHn, Personality, phoneMinimumCharacters } from 'src/app/@core/constants';
import { AddContactModalService } from 'src/app/@core/services/add-contact-modal.service';
import { ColumnTable } from 'src/app/models/column-table';
import { Customer } from 'src/app/models/customer';
import { Nationality } from 'src/app/models/nationality';
import { Paging } from 'src/app/models/page';
import { Param } from 'src/app/models/param';
import { CustomerUpdate } from 'src/app/models/request/CustomerUpdate';
import { CustomerUpdateContact } from 'src/app/models/request/CustomerUpdateContact';
import { UpdateCustomerRequestModel } from 'src/app/models/request/updateCustomerRequestModel';
import { CustomerCreateResponseModel } from 'src/app/models/response/customerCreateResponseModel';
import { CustomerUpdateResponse } from 'src/app/models/response/CustomerUpdateResponse';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NationalityService } from 'src/app/services/nationality.service';
import { AuthService } from 'src/app/services/auth.service';
import { OnbaseService } from 'src/app/services/onbase.service';
import { ParamsService } from 'src/app/services/params.service';
import { PosCustomerCreateAddressComponent } from '../pos-customer-create/pos-customer-create-address/pos-customer-create-address.component';
import Swal from 'sweetalert2';
import { CustomerVerificationService } from 'src/app/services/customer-verification.service';
import { PosVerifyCustomerModalService } from 'src/app/@core/services/pos-verify-customer-modal.service';
import { VerifyCustomerCheckResponse } from 'src/app/models/response/VerifyCustomerCheckResponse';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PosReasonSelectModalService } from 'src/app/@core/services/pos-reason-select-modal.service';
import { CatalogsResponse } from 'src/app/models/catalogs';
import { ReasonLogRequest } from 'src/app/models/request/reason-log-request-model';
@Component({
  selector: 'app-pos-customer-update',
  templateUrl: './pos-customer-update.component.html',
  styleUrls: ['./pos-customer-update.component.sass']
})
export class PosCustomerUpdateComponent implements OnInit {
  @Input() showBack = true;
  @Input() redirect = true;
  @Input() showHeader = true;

  @Input() customer: Customer = new Customer();
  @Input() customerVerifyData:VerifyCustomerCheckResponse | undefined = {data:{email:{verified:false,date_verified:``,email:``,phone_number:``},phone:{verified:false,date_verified:``,email:``,phone_number:``}}};

  customerResponse: CustomerUpdateResponse = new CustomerUpdateResponse();

  formCustomerUpdate: FormGroup = this.fb.group({});
  nationalities: Array<Nationality> = new Array<Nationality>();
  countryResidenceMinLength: number = 0;
  countryResidenceSelected: String = CurrentCountry;

  phoneMaskHn: string = "";
  phoneMaskNoHn: string = "";
  phoneMask: string = "";

  maritalStatusList: Array<Param> = new Array<Param>();
  matiralStatusSelected: String = "";
  references: Paging<Array<CustomerUpdateContact>> = new Paging([]);

  file1: FileList | null = null;
  file2: FileList | null = null;
  file3: FileList | null = null;
  file4: FileList | null = null;
  file5: FileList | null = null;
  file6: FileList | null = null;
  file7: FileList | null = null;
  file8: FileList | null = null;
  file9: FileList | null = null;
  file10: FileList | null = null;

  @ViewChild("FormAddessCustomerPerson") formAddessCustomerPerson!: PosCustomerCreateAddressComponent;
  @ViewChild("FormAddessWorkCustomerPerson") FormAddessWorkCustomerPerson!: PosCustomerCreateAddressComponent;

  @Output() updateCompleted = new EventEmitter<boolean>();

  paramSkippedVeficationBranches = ``;

  constructor(
    private fb: FormBuilder,
    private paramsService: ParamsService,
    private nationalityServices: NationalityService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private router: Router,
    private location: Location,
    private configService: ConfigurationService,
    private authService: AuthService,
    private onBaseService: OnbaseService,
    private addContactModalService: AddContactModalService,
    private verifyCustmerModalService:PosVerifyCustomerModalService,
    private customerVerificationService: CustomerVerificationService,
    private paramService:ParamsService,
    private localStorageService:LocalStorageService,
    private reasonSelectModalService:PosReasonSelectModalService) {
    this.customerVerifyData = this.localStorageService.getObject('customerVerifyData') as VerifyCustomerCheckResponse;
  }

  async ngOnInit(): Promise<void> {
    this.buildForm();

    if(this.showHeader){
      const code = this.route.snapshot.params['id'];
      this.findCustomer(code);
    }

    this.nationalities = (await this.nationalityServices.readNationalities().toPromise()).response.content;
    this.nationalities = this.nationalities.sort((a, b) => a.name.localeCompare(b.name));
    this.maritalStatusList = (await this.onBaseService.readOnBaseCatalogueObject(OnBaseCatalogueType.MARITALSTATUS, undefined).toPromise());

    let params: any = (await this.paramsService.readParams().toPromise()).response;
    this.phoneMaskHn = params[ParamPhoneMaskHn];
    this.phoneMaskNoHn = params[ParamPhoneMaskNoHn];
    this.countryResidenceMinLength = Number(params[phoneMinimumCharacters]);
    this.addMaskInput(this.countryResidenceSelected.toString());

    this.paramSkippedVeficationBranches = (await this.paramService.readParam('verification-valkyria-skipped-branches').toPromise()).response.value;
  }

  buildForm() {
    this.formCustomerUpdate = this.fb.group({
      firstName: [''],
      middleName: [''],
      firstLastName: [''],
      secondLastName: [''],
      maritalStatus: [''],
      email: [this.customerVerifyData?.data?.email?.verified ? this.customerVerifyData?.data?.email?.email:'', [Validators.email]],
      phone: [this.customerVerifyData?.data?.phone?.verified ? this.customerVerifyData?.data?.phone?.phone_number:'', [Validators.required]],
      phoneResidence: [''],
      phoneWork: [''],
      phoneWorkExtension: [''],
      workPosition: [''],
      companyName: [''],
      workDepartment: [''],
      observations: ['']
    });
  }

  async findCustomer(code: any) {
    this.customer = (await this.customerService.readCustomerByCode(code).toPromise());
  }

  countryResidenceMinimunCharacters(event: KeyboardEvent, control: string) {
    this.setCountryResidenceMinimunCharacters((event.target as HTMLInputElement).value, control);
  }

  setCountryResidenceMinimunCharacters(countryResidence: String, control: string) {
    if (countryResidence.length > 0)
      this.formCustomerUpdate.get(control)?.setValidators([Validators.minLength(this.countryResidenceMinLength)]);
    else
      this.formCustomerUpdate.get(control)?.clearValidators();

    this.formCustomerUpdate.get(control)?.updateValueAndValidity();
  }

  addMaskInput(countryCode: string) {
    if (countryCode == CountryHN)
      this.phoneMask = this.phoneMaskHn;
    else
      this.phoneMask = this.phoneMaskNoHn;
  }

  // old updateCustomer
  // async updateCustomer() {

  //   if (this.formCustomerUpdate.valid && this.formAddessCustomerPerson.formCustomerAddress.valid && this.FormAddessWorkCustomerPerson.formCustomerAddress.valid) {
  //     const request = this.mapFormToCustomerRequestModel();

  //     this.customerResponse = (await this.customerService.updateCustomer(this.customer.code, request, this.getFiles()).toPromise()).response;
  //     this.toastr.success("Cliente actualizado correctamente, soolitud");
  //     Swal.fire({
  //       title: 'Actualización de cliente',
  //       text: 'Se actualizo el cliente correctamente, Solicitud N:' + this.customerResponse.ApplicationId,
  //       icon: 'success',
  //       confirmButtonText: 'Aceptar',
  //     }).then((result) => {
  //       this.router.navigate(['customer', this.customer.code], { replaceUrl: true });
  //     })
  //   } else {
  //     this.toastr.warning("Ingrese todos los campos obligatorios");
  //   }
  // }

  async updateCustomer() {

    if (this.paramSkippedVeficationBranches.includes(this.configService.readSelectedBranchAndSalesOrg().branch.code)) {
      if (this.formCustomerUpdate.valid
        && this.formAddessCustomerPerson.formCustomerAddress.valid
        && this.FormAddessWorkCustomerPerson.formCustomerAddress.valid) {

        await this.generateCreditRequestToUpdateCustomer();

      } else {
        this.toastr.warning("Ingrese todos los campos obligatorios");
      }
    } else {
      // logica para validar si solo esta actualizando correo o numero
      if (this.checkIsNotUpdatingOtherData()) {
        if(this.formCustomerUpdate.get('phone')?.value != ''
        && this.customerVerifyData?.data.phone.verified
        && this.formCustomerUpdate.get('email')?.value == ''){
          this.toastr.info('El teléfono ya está verificado. Favor verifique el correo electrónico.','Atencion!!');
          return;
        }
          if(this.formCustomerUpdate.valid
            && this.formAddessCustomerPerson.formCustomerAddress.valid
            && this.FormAddessWorkCustomerPerson.formCustomerAddress.valid){

            const verifyCustomerResult = await this.verifyCustomerPhoneAndEmail();

            if (verifyCustomerResult) { //TODO:deberia de hacer el emit hasta que la verificacion sea existosa?
              this.toastr.success(`Verificacion de datos exitosa.`);
              if (this.redirect) {
                this.router.navigate(['customer', this.customer.code], { replaceUrl: true });
              }
            }

            this.updateCompleted.emit(verifyCustomerResult);
            this.updateCompleted.complete();
            return;
          }else{
            this.toastr.warning("Ingrese todos los campos obligatorios");
          }
      } else {

        if (this.formCustomerUpdate.valid
          && this.formAddessCustomerPerson.formCustomerAddress.valid
          && this.FormAddessWorkCustomerPerson.formCustomerAddress.valid) {

            if ((!this.customerVerifyData?.data.email.verified && this.formCustomerUpdate.get('email')?.value != '') ||
            (!this.customerVerifyData?.data.phone.verified && this.formCustomerUpdate.get('phone')?.value != '')) {
              const verifyCustomerResult = await this.verifyCustomerPhoneAndEmail();
            }

          await this.generateCreditRequestToUpdateCustomer();

        } else {
          this.toastr.warning("Ingrese todos los campos obligatorios");
        }
      }
    }
  }

  private async generateCreditRequestToUpdateCustomer() {
    const request = this.mapFormToCustomerRequestModel();

    this.customerResponse = (await this.customerService.updateCustomer(this.customer.code, request, this.getFiles()).toPromise()).response;
    this.toastr.success("Cliente actualizado correctamente, solicitud");
    Swal.fire({
      title: 'Actualización de cliente',
      text: 'Se actualizo el cliente correctamente, Solicitud N:' + this.customerResponse.ApplicationId,
      icon: 'success',
      confirmButtonText: 'Aceptar',
    }).then((result) => {
      if (this.redirect) {
        this.router.navigate(['customer', this.customer.code], { replaceUrl: true });
      }
      this.updateCompleted.emit(true);
      this.updateCompleted.complete();
    });
  }



  mapFormToCustomerRequestModel(): CustomerUpdate {
    let updateCustomerRequestModel = new CustomerUpdate();

    updateCustomerRequestModel.source = "POSFRANQUICIA";
    updateCustomerRequestModel.observations = this.formCustomerUpdate.get('observations')?.value;
    updateCustomerRequestModel.newPartnerData.firstName = this.formCustomerUpdate.get('firstName')?.value;
    updateCustomerRequestModel.newPartnerData.middleName = this.formCustomerUpdate.get('middleName')?.value;
    updateCustomerRequestModel.newPartnerData.firstLastName = this.formCustomerUpdate.get('firstLastName')?.value;
    updateCustomerRequestModel.newPartnerData.secondLastName = this.formCustomerUpdate.get('secondLastName')?.value;

    if (this.formCustomerUpdate.get('maritalStatus') != undefined)
      updateCustomerRequestModel.newPartnerData.civilStatus = this.maritalStatusList.find(x => x.code == this.formCustomerUpdate.get('maritalStatus')?.value)?.value!;

    updateCustomerRequestModel.newPartnerData.mobilePhone = this.formCustomerUpdate.get('phone')?.value;
    updateCustomerRequestModel.newPartnerData.homePhone = this.formCustomerUpdate.get('phoneResidence')?.value;
    updateCustomerRequestModel.newPartnerData.workPhoneNumber = this.formCustomerUpdate.get('phoneWork')?.value;
    updateCustomerRequestModel.newPartnerData.extension = this.formCustomerUpdate.get('phoneWorkExtension')?.value;
    updateCustomerRequestModel.newPartnerData.email = this.formCustomerUpdate.get('email')?.value;
    updateCustomerRequestModel.newPartnerData.companyName = this.formCustomerUpdate.get('companyName')?.value;
    updateCustomerRequestModel.newPartnerData.workPosition = this.formCustomerUpdate.get('workPosition')?.value;
    updateCustomerRequestModel.newPartnerData.workDepartment = this.formCustomerUpdate.get('workDepartment')?.value;

    let formResponseAddress = this.formAddessCustomerPerson.mapRequestAddress();
    updateCustomerRequestModel.newPartnerData.stateProvince = formResponseAddress.departmentName;
    updateCustomerRequestModel.newPartnerData.municipality = formResponseAddress.municipalityName;
    updateCustomerRequestModel.newPartnerData.district = formResponseAddress.neighborhoodName;
    updateCustomerRequestModel.newPartnerData.addressLine1 = formResponseAddress.street;
    updateCustomerRequestModel.newPartnerData.addressLine2 = formResponseAddress.street2;
    updateCustomerRequestModel.newPartnerData.addressLine3 = formResponseAddress.street3;
    updateCustomerRequestModel.newPartnerData.addressLine4 = formResponseAddress.street4;
    updateCustomerRequestModel.newPartnerData.addressLine5 = formResponseAddress.street5;

    let formResponseAddressWork = this.FormAddessWorkCustomerPerson.mapRequestAddress();
    updateCustomerRequestModel.newPartnerData.companyStateProvince = formResponseAddressWork.departmentName;
    updateCustomerRequestModel.newPartnerData.companyMunicipality = formResponseAddressWork.municipalityName;
    updateCustomerRequestModel.newPartnerData.companyDistrict = formResponseAddressWork.neighborhoodName;
    updateCustomerRequestModel.newPartnerData.companyAddressLine1 = formResponseAddressWork.street;
    updateCustomerRequestModel.newPartnerData.companyAddressLine2 = formResponseAddressWork.street2;
    updateCustomerRequestModel.newPartnerData.companyAddressLine3 = formResponseAddressWork.street3;
    updateCustomerRequestModel.newPartnerData.companyAddressLine4 = formResponseAddressWork.street4;
    updateCustomerRequestModel.newPartnerData.companyAddressLine5 = formResponseAddressWork.street5;

    updateCustomerRequestModel.contacts = this.references.content;

    return updateCustomerRequestModel;
  }

  async goBack() {
    const customerVerifyCheckData = (
      await this.customerVerificationService
        .getCustomerContactInformationVerification(this.customer.code)
        .toPromise()
    ).response.data;

    if(((customerVerifyCheckData?.email?.email != undefined || true)&&(!customerVerifyCheckData.email.verified)) ||
        ((customerVerifyCheckData?.phone?.phone_number != undefined || true)&&(!customerVerifyCheckData.phone.verified))){
          const reason = await this.reasonSelectModalService.show('notVerifyCustomer');
          await this.saveReasonLog(reason!);
    }

    this.location.back();
  }

  async saveReasonLog(selectedReason:CatalogsResponse):Promise<void>{
    const requestToSaveReasonLog:ReasonLogRequest = {
      branchCode:this.configService.readSelectedBranchAndSalesOrg().branch.code,
      component:'VERIFY_CUSTOMER',
      customerCode:this.customer.code,
      description:selectedReason?.description || "",
      reasonCode:selectedReason?.itemCode || "RA",
      sellerCode:(await this.authService.readProfile()).username,
      salesOrgCode:this.configService.readSelectedBranchAndSalesOrg().salesOrg.code,
      yesOrNo:'NO'
    };

    await this.customerVerificationService.saveReasonToNotUpdateCustomer(requestToSaveReasonLog).toPromise();
  }

  getFiles(): Map<string, FileList> {
    let files = new Map<string, FileList>();
    if (this.file1 != undefined)
      files.set("1-104", this.file1);
    if (this.file2 != undefined)
      files.set("2-105", this.file2);
    if (this.file3 != undefined)
      files.set("3-105", this.file3);
    if (this.file4 != undefined)
      files.set("4-104", this.file4);
    if (this.file5 != undefined)
      files.set("5-105", this.file5);
    if (this.file6 != undefined)
      files.set("6-104", this.file6);
    if (this.file7 != undefined)
      files.set("7-105", this.file7);
    if (this.file8 != undefined)
      files.set("8-105", this.file8);
    if (this.file9 != undefined)
      files.set("9-105", this.file9);
    if (this.file10 != undefined)
      files.set("10-105", this.file10);


    return files;
  }

  onFileChange(event: any, numberFile: number) {
    let file: FileList = event.target.files as FileList;
    if (file && file.length) {
      switch (numberFile) {
        case 1:
          this.file1 = file;
          break;
        case 2:
          this.file2 = file;
          break;
        case 3:
          this.file3 = file;
          break;
        case 4:
          this.file4 = file;
          break;
        case 5:
          this.file5 = file;
          break;
        case 6:
          this.file6 = file;
          break;
        case 7:
          this.file7 = file;
          break;
        case 8:
          this.file8 = file;
          break;
        case 9:
          this.file9 = file;
          break;
        case 10:
          this.file10 = file;
          break;
      }
    }
  }

  getReferencesHeaders(): Array<ColumnTable> {
    return [
      new ColumnTable("firstName", "Primer Nombre"),
      new ColumnTable("middleName", "Segundo Nombre"),
      new ColumnTable("firstLastName", "Primer Apellido"),
      new ColumnTable("secondLastMame", "Segundo Apellido"),
      new ColumnTable("tipoRelacion", "Relación"),
      new ColumnTable("delete", "Descuento", {
        type: 'action', actions: [
          {
            fn: (contact) => this.removeContact(contact),
            icon: 'fa fa-times text-danger',
            tooltip: "Eliminar contacto",
            isDisabled: false,
          }
        ],
        iconClass: "fa fa-cogs",
        isIcon: true
      })
    ]
  }

  removeContact(contact: CustomerUpdateContact) {
    this.references.content.splice(contact.index, 1);
  }

  async showContacts() {
    await this.addContactModalService.show((customerUpdateContact: CustomerUpdateContact) => {
      customerUpdateContact.index = this.references.content.length - 1;
      this.references.content.push(customerUpdateContact);
    });
  }

  checkThereIsAnotherFieldWithData(formToCheck:FormGroup):boolean{
    let result = false;
    Object.keys(formToCheck.getRawValue()).forEach( key => {
      if(key !== 'email' && key !== 'phone'){
        if(formToCheck.get(key)?.value != undefined && formToCheck.get(key)?.value != ''){
          result = true;
        }
      }
    });
    return result;
  }

  private async verifyCustomerPhoneAndEmail() {
    const customerVerifyCheckData = (
      await this.customerVerificationService
        .getCustomerContactInformationVerification(this.customer.code)
        .toPromise()
    ).response.data;
    let updatedPhoneValue = this.formCustomerUpdate.get('phone')?.value;
    const updatedEmailValue = this.formCustomerUpdate.get('email')?.value;
    if(updatedPhoneValue.includes('-')){
      updatedPhoneValue = updatedPhoneValue.replace('-','');
    }

    if (updatedPhoneValue != '' && updatedPhoneValue != customerVerifyCheckData.phone.phone_number) {
      customerVerifyCheckData.phone.phone_number = this.formCustomerUpdate.get('phone')?.value;
      customerVerifyCheckData.phone.verified = false;
    }

    if (updatedEmailValue != '' && updatedEmailValue != customerVerifyCheckData.email.email) {
      customerVerifyCheckData.email.email = this.formCustomerUpdate.get('email')?.value;
      customerVerifyCheckData.email.verified = false;
    }

    const verifyCustomerResult = await this.verifyCustmerModalService.show(customerVerifyCheckData, this.customer.code);
    return verifyCustomerResult;
  }

  private checkIsNotUpdatingOtherData() {
    const resultCustomerForm = !this.checkThereIsAnotherFieldWithData(this.formCustomerUpdate);
    const resultCustomerFormAddress = !this.checkThereIsAnotherFieldWithData(this.formAddessCustomerPerson.formCustomerAddress);
    const resultCustomerFormWork = !this.checkThereIsAnotherFieldWithData(this.FormAddessWorkCustomerPerson.formCustomerAddress);
    return resultCustomerForm && resultCustomerFormAddress && resultCustomerFormWork;
  }
}
