import { CatalogsService } from 'src/app/services/catalogs.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CatalogsResponse } from 'src/app/models/catalogs';


@Component({
  selector: 'app-pos-reason-select-modal',
  templateUrl: './pos-reason-select-modal.component.html',
  styleUrls: ['./pos-reason-select-modal.component.sass'],
})
export class PosReasonSelectModalComponent implements OnInit {
  @Input() catalogCode: string = '';
  @Output() selectedReasonChange: EventEmitter<CatalogsResponse | undefined> =
    new EventEmitter();

  reasons: CatalogsResponse[] = [];
  selectedReason: CatalogsResponse | undefined;

  public reasonForm = this.formBuilder.group({
    reasonCode: this.formBuilder.control(null, Validators.required),
    reasonDescription: this.formBuilder.control(null, Validators.required),
    manualReasonDescription:this.formBuilder.control(null)
  });

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastr:ToastrService,
    private catalogsService:CatalogsService
  ) {}

   ngOnInit(): void {

  }

  cancel() {
    if(this.selectedReason != undefined){
      if(this.selectedReason.isTextFreeDescription){
        if(this.reasonForm.get('manualReasonDescription')?.value == null){
          this.toastr.warning('Debe de ingresar un motivo')
          return;
        }
      }
      this.bsModalRef.hide();
      this.selectedReasonChange.emit(this.selectedReason);
      this.selectedReasonChange.complete();
    }else{
      this.toastr.warning('Debe de seleccionar un motivo');
    }
  }

  async confirm(): Promise<void> {
    if(this.selectedReason != undefined){
      if(this.selectedReason.isTextFreeDescription){
        if(this.reasonForm.get('manualReasonDescription')?.value == null){
          this.toastr.warning('Debe de ingresar un motivo')
          return;
        }
        this.selectedReason.description =
          `${this.selectedReason.description}-${this.reasonForm.get('manualReasonDescription')?.value}`;
      }
      this.selectedReasonChange.emit(this.selectedReason);
      this.selectedReasonChange.complete();
      this.bsModalRef.hide();
    }else{
      this.toastr.warning('Debe de seleccionar un motivo');
    }
  }

  changeType() {
    this.selectedReason = this.reasons.find(
      (reason) => reason.code === this.reasonForm.get('reasonCode')?.value
    );
    this.reasonForm.patchValue({
      reasonDescription: this.selectedReason?.description,
    });
  }

  async loadReasons():Promise<void>{
    this.reasons = (
      await this.catalogsService
        .getCatalogByCatalogCode(this.catalogCode)
        .toPromise()
    ).response.content;
  }
}
